import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import LogoImageLight from '../../assets/Branding/Key-OnDark.png';

const menuItems = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "Services",
    link: "/services",
    subMenu: [
      { label: "Comprehensive SEO Optimization & Digital Growth Services", link: "/services/seo" },
      { label: "Developer Training & Team Enhancement Programs", link: "/services/training" },
      { label: "Custom Technical Consulting & System Design", link: "/services/consulting" },
      { label: "Strategic Solutions Architecture & Process Mapping", link: "/services/process-mapping" },
      { label: "Team Building & Talent Acquisition Consulting", link: "/services/team-building" },
      { label: "Contract Development/Individual Work", link: "/services/contract-development" },
    ],
  },
  {
    label: "About",
    link: "/about",
    subMenu: [
      { label: "Us", link: "/about/us" },
      { label: "Contact", link: "/about/contact" },
      { label: "Privacy Policy", link: "/about/privacy-policy" },
      { label: "Terms And Conditions", link: "/about/terms-of-service" },
    ],
  },
  // {
  //   label: "Blog",
  //   link: "/blog",
  //   newTab: true,
  // },
];

function Header({ className }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMenuOpen = (event, menuLabel) => {
    setAnchorEl({ anchor: event.currentTarget, label: menuLabel });
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const renderSubMenu = (subMenu) => (
    <Menu
      anchorEl={anchorEl?.anchor}
      open={Boolean(anchorEl) && anchorEl.label === subMenu.label}
      onClose={handleMenuClose}
    >
      {subMenu.subMenu.map((item) => (
        <MenuItem key={item.label} onClick={handleMenuClose}>
          <Button component={Link} to={item.link} style={{ color: 'inherit', width: '100%', height: '100%', textTransform: 'none' }}>
            <Typography variant="inherit" style={{ textAlign: 'left', flexGrow: 1 }}>
              {item.label}
            </Typography>
          </Button>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <header className={className}>
      <AppBar
        position="static"
        sx={{
          background: 'linear-gradient(180deg, #C1246B 0%, #E13661 60%,  #FD4C55 80%)',
          padding: '18px',
        }}>
        <Toolbar>
          {/* Logo */}
          <img
            onClick={() => {
              window.location.href = '/';
            }}
            src={LogoImageLight}
            alt="Codeductor Logo"
            style={{ height: '44px' }}
          />
          <Box
            sx={{ flexGrow: 1, cursor: "pointer" }}
          >
            <Button component={Link} to="/" style={{ textDecoration: 'none', color: '#FFF', width: '129px', height: '48px', textTransform: 'none' }}>
              Codeductor
            </Button>
          </Box>

          {/* Desktop Navigation */}
          <Box sx={{ display: { xs: "none", md: "flex" }, color: '#FFF', alignContent: 'left' }}>
            {menuItems.map((item) =>
              item.subMenu ? (
                <React.Fragment key={item.label}>
                  <Button
                    color="inherit"
                    onClick={(e) => handleMenuOpen(e, item.label)}
                    endIcon={<ExpandMoreIcon />}
                    style={{ textDecoration: 'none', color: 'inherit', textTransform: 'none' }}
                  >
                    {item.label}
                  </Button>
                  {anchorEl && anchorEl.label === item.label && renderSubMenu(item)}
                </React.Fragment>
              ) : (
                <Button
                  key={item.label}
                  color="inherit"
                  component={Link}
                  to={item.link}
                  target={item.newTab ? "_blank" : "_self"}
                  style={{ textDecoration: 'none', color: 'inherit', textTransform: 'none' }}
                >
                  {item.label}
                </Button>
              )
            )}
            <Button variant='contained' color='primary' sx={{ marginLeft: 2, color: '#FFF', textTransform: 'none' }} onClick={() => window.location.href = '/about/contact/request'}>
              Get A Quote
            </Button>
          </Box>

          {/* Mobile Menu Icon */}
          <Box sx={{ display: { xs: "flex", md: "none", color: '#FFF' } }}>
            <IconButton
              color="inherit"
              edge="end"
              onClick={toggleDrawer(true)}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Navigation */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: {
            background: 'linear-gradient(180deg, #AC3287 1%, #E1486F 80%, #FF8769 100%)',
            color: '#FFF',
          },
        }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List
            sx={{
              padding: 0,
            }}
          >
            {menuItems.map((item) => (
              <React.Fragment key={item.label}>
                <ListItem
                  sx={{
                    '&:hover': {
                      backgroundColor: '#FF9C84',
                    },
                  }}
                >
                  <Button
                    component={Link}
                    to={item.link}
                    style={{ color: 'inherit', textTransform: 'none' }}
                  >
                      <ListItemText primary={item.label} />
                  </Button>
                </ListItem>
                {item.subMenu && (
                  <List component="div" disablePadding>
                    {item.subMenu.map((subItem) => (
                      <ListItem
                        key={subItem.label}
                        sx={{ pl: 4, '&:hover': { backgroundColor: '#FF9C84' } }}
                      >
                        <Button
                          component={Link}
                          to={subItem.link}
                          style={{ textDecoration: 'none', color: 'inherit', width: '100%', height: '100%', textTransform: 'none' }}
                        >
                          <ListItemText primary={subItem.label} />
                        </Button>
                      </ListItem>
                    ))}
                  </List>
                )}
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
        <Button variant='contained' color='primary' sx={{ margin: 2, color: '#FFF' }} onClick={() => window.location.href = '/about/contact/request'}>
          Get A Quote
        </Button>
      </Drawer>
    </header >
  );
}

export default Header;
