

const profiles = [
    {
        is_available: true,
        name: 'Brent Scarafone',
        description: 'Brent is a seasoned professional with over 15 years of experience in consulting and system design.',
        experience: 15,
        rate: 102,
        // image: 'https://via.placeholder.com/96',
        support: [
            'consulting',
            'seo',
            'developer-training',
            'system-design',
            'solution-architect',
            'team-building',
            'contractor',
            'individual-contributor',
        ],
    },
    {
        is_available: true,
        name: 'Vincent Castelli',
        description: 'Vincent is a technical expert with over 10 years of experience in system design and documentation.',
        experience: 5,
        rate: 60,
        // image: 'https://via.placeholder.com/96',
        support: [
            // 'individual-contributor',
            // 'graphics',
            'consulting',
            'seo',
            'developer-training',
            'system-design',
            'solution-architect',
            'team-building',
            'contractor',
            'individual-contributor',
        ],
    },
    {
        is_available: true,
        name: 'Justin Kahrs',
        description: 'Justin is a software engineer with over 8 years of experience in system design and implementation strategies.',
        experience: 8,
        rate: 70,
        // image: 'https://via.placeholder.com/96',
        support: [
            // 'consulting',
            // 'system-design',
            // 'solution-architect',
            'consulting',
            'seo',
            'developer-training',
            'system-design',
            'solution-architect',
            'team-building',
            'contractor',
            'individual-contributor',
        ],
    },
    {
        is_available: true,
        name: 'Chris Williams',
        description: 'Chris is a seasoned professional with over 10 years of experience in graphic design.',
        experience: 10,
        rate: 70,
        // image: 'https://via.placeholder.com/96',
        support: [
            'graphics',
            'individual-contributor',
        ],
    },

    // Add more profiles as needed
];

export default profiles.filter(profile => profile.is_available);