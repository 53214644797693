import { Paper, Container, Typography, Button, Card, CardContent, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import sections from '../../../services/servicesAPI.js';


function ServicesPage() {

    const ServicesSection = () => {
        return (
            <Grid container spacing={4} sx={{ padding: 4 }}>
                {sections.map((section) => (
                    <Grid item xs={12} sm={6} md={4} key={section.id}>
                        <Card
                            elevation={1}
                            sx={{
                                borderRadius: '0.5rem',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: 3,
                                },
                                textAlign: 'center',
                            }}
                        >
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h6" component="h2" gutterBottom>
                                    {section.title}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    {section.flavorText}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {section.description}
                                </Typography>
                            </CardContent>
                            <Button
                                variant="contained"
                                component={Link}
                                to={section.link}
                                sx={{
                                    margin: 2,
                                    color: '#fff',
                                    backgroundColor: '#AB4A8E'
                                }}
                            >
                                {section.callToActionText}
                            </Button>
                        </Card>
                    </Grid>
                ))
                }
            </Grid >
        );
    };



    return (
        <Container
            style={{
                margin: '0 auto',
                padding: '18px',
                width: 'calc(100vw - 44px)',
            }}
        >
            <Paper
                elevation={4}
                sx={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    padding: 4,
                    width: '100%',
                }}
            >
                <ServicesSection />
            </Paper>
        </Container>
    );
}

export default ServicesPage;