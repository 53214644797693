import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CssBaseline, ThemeProvider, Box, Container } from '@mui/material';

import ScrollToTop from './components/ScrollToTop/ScrollToTop.jsx';

// Pages
import LoginPage from './pages/Login/Login.jsx';
import Home from './pages/Home/Home.jsx';

// Marketing About Pages
import MarketingLander from './pages/Marketing/Marketing.jsx';
import AboutUs from './pages/Marketing/About/Us/Us.jsx';
import AboutContactUs from './pages/Marketing/About/Contact/Contact.jsx';
import AboutTermsOfService from './pages/Marketing/About/TermsOfService/TermsOfService.jsx';
import AboutPrivacyPolicy from './pages/Marketing/About/PrivacyPolicy/PrivacyPolicy.jsx';
import SiteMap from './pages/Marketing/About/Sitemap/Sitemap.jsx';
import RequestQuote from './pages/Marketing/About/Contact/Request/Quote.jsx';

// Marketing Blog
import Blog from './pages/Marketing/Blog/Blog.jsx';

// Marketing Services
import Services from './pages/Marketing/Services/Services.jsx';
import AppDevelopment from './pages/Marketing/Services/AppDevelopment/AppDevelopment.jsx';
import Consulting from './pages/Marketing/Services/Consulting/Consulting.jsx';
import DeveloperTraining from './pages/Marketing/Services/DeveloperTraining/DeveloperTraining.jsx';
import SEO from './pages/Marketing/Services/SEO/SEO.jsx';
import SolutionsArchitect from './pages/Marketing/Services/SolutionsArchitect/SolutionsArchitect.jsx';
import TeamBuilding from './pages/Marketing/Services/TeamBuilding/TeamBuilding.jsx';

// Components
import Header from './components/Header/Header.jsx';
import Sidebar from './components/Sidebar/Sidebar.jsx';
import Footer from './components/Footer/Footer.jsx';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs.jsx';
import PrivateRoute from './components/PrivateRoute/PrivateRoute.jsx';

// Themes
import '@fontsource/roboto';
import mainTheme from './styles/theme.js';
import './App.css';

// Contexts
import { AuthProvider, useAuth } from './contexts/AuthContext/AuthContext.jsx';


function AppContent() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundColor: 'rgb(255, 218, 194)',
    }}>
      <ScrollToTop />
      {isAuthenticated && (
        <>
          {<Sidebar />}
          {<Breadcrumbs />}
          <Box style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Routes>
              <Route path="*" element={<PrivateRoute><Home /></PrivateRoute>} />
            </Routes>
          </Box>
        </>
      )}

      {!isAuthenticated && (
        <>
          {(!isLoginPage) && <Header />}
          <Routes>
            {/* Services Routes */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/contract-development" element={<AppDevelopment />} />
            <Route path="/services/consulting" element={<Consulting />} />
            <Route path="/services/team-building" element={<TeamBuilding />} />
            <Route path="/services/training" element={<DeveloperTraining />} />
            <Route path="/services/seo" element={<SEO />} />
            <Route path="/services/process-mapping" element={<SolutionsArchitect />} />
            {/* About Routes */}
            <Route path="/about" element={<AboutUs />} />
            <Route path="/about/us" element={<AboutUs />} />
            <Route path="/about/contact" element={<AboutContactUs />} />
            <Route path="/about/contact/request" element={<RequestQuote />} />
            <Route path="/about/privacy-policy" element={<AboutPrivacyPolicy />} />
            <Route path="/about/terms-of-service" element={<AboutTermsOfService />} />
            <Route path="/about/sitemap" element={<SiteMap />} />
            {/* Blog Routes */}
            <Route path="/blog" element={<Blog />} />
            {/* Defaults */}
            <Route path="/" element={<MarketingLander />} />
            <Route path="*" element={<MarketingLander />} />
          </Routes>
          <Footer />
        </>
      )}
    </Box>
  );
}

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
