import React from 'react';
import { Box, Paper, Button, Link, Card, CardContent, CardMedia, Typography, Grid, Avatar } from '@mui/material';

import profiles from '../../services/codeductorProfiles.js';

function AvailableCodeductors({ category }) {
    const profilesWithQuoteOption = [
        ...profiles.filter(profile => profile.support.includes(category)),
        {
            name: profiles.length > 0 ? 'Not at your tempo?' : 'No Codeductors available',
            experience: '',
            description:
                profiles.length > 0
                    ? 'Request a quote and we will find the right Codeductor for you.'
                    : 'Request a quote and we will notify you when a Codeductor becomes available.',
            rate: '',
            image: 'path/to/quote-image.png', // You can use a placeholder image or an icon
            support: ['quote'],
        }
    ];

    return (null);

    return (
        <div
            style={{
                margin: '0 auto',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    textAlign: 'left',
                    padding: '18px 0 0 0',
                    width: '100%',
                }}
            >
                <Typography variant="h6">
                    Available Codeductors
                </Typography>
                <Typography>
                    These are the Codeductors who specialize in Custom Technical Consulting & System Design.
                    Click on their profiles to learn more about their experience and expertise.
                </Typography>
                <br />
                <Grid container spacing={2}>
                    {profilesWithQuoteOption.map((profile, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card
                                elevation={3}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: 2,
                                }}
                            >
                                {!profile.support.includes('quote') && <Avatar
                                    sx={{ width: 96, height: 96 }}
                                    src={profile.image}
                                    alt={profile.name}
                                />}
                                <CardContent sx={{ textAlign: 'center', }}>
                                    <Typography component="div" variant="h5">
                                        {profile.name}
                                    </Typography>
                                    {profile.description && (
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            {profile.description}
                                        </Typography>
                                    )}
                                    {profile.experience && (
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            {profile.experience} years of experience
                                        </Typography>
                                    )}
                                    {profile.rate && (
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            ${profile.rate}/hr
                                        </Typography>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            marginTop: 2,
                                            color: 'white'
                                        }}
                                    >
                                        {profile.support.includes('quote') ? 'Request a Quote' : 'Select Profile'}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );
}

export default AvailableCodeductors;