function Sitemap() {

    return (
        <div>
            <h1>Site Map</h1>
        </div>
    )

}

export default Sitemap;