// Marketing.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Container, Paper, Typography, Grid, CardContent, Card } from '@mui/material';

import './Marketing.css';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import sections from '../../services/servicesAPI.js';


const heroCarousel = [
    {
        id: 'hero-1',
        caption: 'Orchestrating Solutions at Your Tempo',
        subtext: 'Blending technology and strategy to harmonize your business goals.',
        link: { to: '/about/us', text: 'Learn More About Us' },
        url: 'url("/images/HeroCarousel_1.jpg")',
    },
    {
        id: 'hero-2',
        caption: 'Empowering Your Vision with Expertise',
        subtext: 'From SEO to team building, we provide the tools and insights to turn ideas into results.',
        link: { to: '/services', text: 'Explore Our Services' },
        url: 'url("/images/HeroCarousel_2.jpg")',
    },
    // {
    //     id: 'hero-3',
    //     caption: 'Stay Ahead with the Latest Insights',
    //     subtext: 'Explore actionable advice and industry trends to keep your business thriving.',
    //     link: { to: '/blog', text: 'Read the Blog' },
    //     url: 'url("/images/HeroCarousel_3.jpg")',
    // },
]


const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 1
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 1
    }
};




function MarketingHome() {

    const ServicesSection = () => {
        return (
            <Grid container spacing={4} sx={{ padding: 4 }}>
                {sections.map((section) => (
                    <Grid item xs={12} sm={6} md={4} key={section.id}>
                        <Card
                            elevation={1}
                            sx={{
                                borderRadius: '0.5rem',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                    boxShadow: 3,
                                },
                                textAlign: 'center',
                            }}
                        >
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h6" component="h2" gutterBottom>
                                    {section.title}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                    {section.flavorText}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {section.description}
                                </Typography>
                            </CardContent>
                            <Button
                                variant="contained"
                                component={Link}
                                to={section.link}
                                sx={{
                                    margin: 2,
                                    color: '#fff',
                                    backgroundColor: '#AB4A8E'
                                }}
                            >
                                {section.callToActionText}
                            </Button>
                        </Card>
                    </Grid>
                ))
                }
            </Grid >
        );
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                width: '100%',
            }}>

            <Carousel
                arrows
                autoPlay
                autoPlaySpeed={5000}
                draggable
                infinite
                minimumTouchDrag={80}
                pauseOnHover
                responsive={responsive}
                shouldResetAutoplay
                showDots
                slidesToSlide={1}
                swipeable
            >
                {heroCarousel.map((slide, index) => (
                    <div
                        key={slide.id}
                        style={{

                            backgroundImage: slide.url,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: 'calc(100vh - 96px)',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                width: '100%',
                                height: '100%',
                                padding: '2rem',
                                justifyContent: 'center',
                                margin: 'auto',
                                alignContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="h2" sx={{ color: '#FFF', textAlign: 'center' }}>
                                {slide.caption}
                            </Typography>
                            <Typography variant="h5" sx={{ color: '#FFF', textAlign: 'center' }}>
                                {slide.subtext}
                            </Typography>
                            <Button
                                variant="contained"
                                component={Link}
                                to={slide.link.to}
                                sx={{
                                    color: '#fff',
                                    backgroundColor: '#AB4A8E',
                                    minHeight: '4rem',
                                    minWidth: '20rem',
                                    marginTop: '96px',
                                }}
                            >
                                <Typography
                                    variant="p"
                                    sx={{
                                        color: '#FFF',
                                        textAlign: 'center'
                                    }}
                                    style={{
                                        fontSize: '1rem',
                                    }}
                                >
                                    {slide.link.text}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                ))}
            </Carousel>
            <ServicesSection />
        </Box>
    );
}

export default MarketingHome;
