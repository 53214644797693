import { Paper, Container, Button, Link, Typography } from '@mui/material';
import Markdown from 'react-markdown';


const markdown = `
# About Us
> Solutions at your tempo

## Who We Are
Founded by a passionate technologist with over two decades of experience, Codeductor combines technical expertise, creative problem-solving, and a human touch. Having delivered over 20 successful apps to app stores and built numerous high-performing teams, we bring a wealth of knowledge to every collaboration.

From startups to enterprises, we thrive on crafting solutions that resonate—be it custom applications, strategic consulting, or team development. With access to a network of professionals boasting decades of additional expertise, we ensure every note in your project is played with precision and care.

## What Drives Us
- **Innovation**: We leverage cutting-edge technologies to deliver creative and impactful solutions.
- **Collaboration**: Like a conductor leading an orchestra, we align diverse elements to work seamlessly toward a shared goal.
- **Empowerment**: Our goal is to leave every client stronger, smarter, and ready to tackle their next challenge.
- **Human Connection**: We believe technology is most powerful when it serves people, and we bring a personal, human approach to every project.

## Our Values
- **Integrity**: We prioritize transparency, honesty, and trust in every interaction.
- **Excellence**: From ideation to execution, we aim for nothing less than exceptional.
- **Adaptability**: The world of technology moves fast, and so do we—always evolving to meet your needs.


## Why Choose Codeductor
- A proven track record of delivering results, from successful app launches to streamlined business processes.
- A conversational, human-first approach that makes even complex projects feel manageable and collaborative.
- The ability to scale and adapt, offering tailored solutions for businesses of all sizes.
Whether you're looking to boost your SEO, train your development team, or bring a bold idea to life, Codeductor is here to guide you—one harmonious solution at a time.
`


function AboutUs() {

    return (
        <Container
            sx={{
                padding: 4,
            }}
        >
            <Paper
                sx={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    padding: 4,
                    width: '100%',
                }}
            >
                <Markdown>
                    {markdown}
                </Markdown>
                <Typography>
                    Ready to get started?
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    href="/about/contact/request"
                    sx={{
                        color: 'white',
                        width: '256px',
                        height: '48px',
                        margin: '10px 0 20px 0',
                    }}
                >
                    Get a Quote
                </Button>
                <Typography>
                    Or learn more about our services.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    href="/services"
                    sx={{
                        color: 'white',
                        width: '256px',
                        height: '48px',
                        margin: '10px 0 20px 0',
                    }}
                >
                    All Services
                </Button>
            </Paper>
        </Container>
    )

}

export default AboutUs;