import { Paper, Container, Button, Link } from '@mui/material';
import Markdown from 'react-markdown';

import AvailableCodeductors from '../../../../components/AvailableCodeductors/AvailableCodeductors.jsx';



const markdown = `
# Comprehensive SEO Optimization & Digital Growth Services
Improve your online visibility and attract more customers with our tailored SEO services. From technical site audits and crawlability fixes to strategic keyword targeting and content optimization, we help your business rank higher and grow faster.

## What We Offer
- **SEO Audits:** Comprehensive technical audits to identify issues and opportunities.
- **Keyword Research:** Strategic keyword targeting to improve search rankings.
- **Content Optimization:** Tailored content strategies to attract and engage your audience.
- **Link Building:** High-quality link building to boost your site authority.
- **Analytics & Reporting:** Detailed reports to track progress and measure success.

## Why Choose Us
With two decades of experience in technology and app development, we understand what it takes to make your digital presence stand out. Our technical expertise ensures every aspect of your SEO strategy is tailored to achieve measurable results, from content optimization to advanced site architecture improvements.

## How it Works
1. **Consultation:** We start with a consultation to understand your unique needs and challenges.
2. **Audit:** Our team conducts a comprehensive audit to identify issues and opportunities.
3. **Strategy:** We craft a customized SEO strategy to improve your online visibility.
4. **Implementation:** We work with you to implement the solutions that will drive success.

## Pricing
Pricing is based on the scope, complexity and the Codeductor selected for your project. Contact us for a custom quote or checkout our online quote tool for an estimate.

`

function SEO() {

    return (
        <Container
            sx={{
                margin: '0 auto',
                padding: '18px',
                width: 'calc(100vw - 44px)',
            }}
        >
            <Paper
                sx={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    padding: 4,
                    width: '100%',
                }}
            >
                <Markdown>
                    {markdown}
                </Markdown>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    href="/about/contact/request"
                    sx={{
                        color: 'white',
                        width: '256px',
                        height: '48px',
                        margin: '10px 0 20px 0',
                    }}
                >
                    Get a Quote
                </Button>
                <AvailableCodeductors category={'seo'} />
            </Paper>
        </Container>
    )

}

export default SEO;