import React, { useState } from 'react';
import {
    Container,
    Box,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Button,
    TextField,
    Grid,
    Paper,
    FormGroup,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from '@mui/material';

import services from '../../../../../services/servicesAPI.js';


const steps = ['Basic Information', 'Service Selection', 'Details & Confirmation'];

const QuoteFlow = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        requested_services: [], // Multi-select services
        details: '',
    });
    const [isLoading, setLoading] = useState(false);

    const averagePrice = formData.service
        ? formData.service.length * 100 // Example calculation
        : 0;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleServiceChange = (serviceId) => {
        setFormData((prevState) => {
            const selectedServices = prevState.requested_services.includes(serviceId)
                ? prevState.requested_services.filter((id) => id !== serviceId)
                : [...prevState.requested_services, serviceId];
            return { ...prevState, requested_services: selectedServices };
        });
    };

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            const submitForm = async () => {
                try {
                    const response = await fetch('/api/v1/contact/request/quote', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData),
                    });
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } catch (error) {
                    alert(error.message);
                } finally {
                    setLoading(false);
                }
            };
            setLoading(true);
            submitForm();
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setFormData({ name: '', email: '', requested_services: [], details: '' });
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Your Name"
                                name="name"
                                fullWidth
                                variant="outlined"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Your Email"
                                name="email"
                                fullWidth
                                variant="outlined"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Which services are you interested in?
                        </Typography>
                        <FormGroup>
                            {services.map((service) => (
                                <FormControlLabel
                                    key={service.id}
                                    control={
                                        <Checkbox
                                            checked={formData.requested_services.includes(service.id)}
                                            onChange={() => handleServiceChange(service.id)}
                                            name="services"
                                        />
                                    }
                                    label={service.title}
                                />
                            ))}
                        </FormGroup>
                    </Box>
                );
            case 2:
                return (
                    <Box>
                        <Typography variant="body1" gutterBottom>
                            Please provide any additional details.
                        </Typography>
                        <TextField
                            label="Details"
                            name="details"
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={4}
                            value={formData.details}
                            onChange={handleChange}
                            required
                        />
                    </Box>
                );
            default:
                return null;
        }
    };
    return (
        <Container
            maxWidth="md"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
            }}
        >
            <Paper elevation={3} sx={{ padding: 3, flexGrow: 1 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Get Your Quote
                </Typography>
                <Stepper activeStep={activeStep} sx={{ mb: 4, overflowX: 'auto' }}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === steps.length ? (
                    <Box textAlign="center">
                        <Typography variant="h6" gutterBottom>
                            Quote Summary
                        </Typography>
                        <Paper variant="outlined" sx={{ p: 2, mb: 4 }}>
                            <Typography variant="body1">
                                <strong>Name:</strong> {formData.name}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Email:</strong> {formData.email}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Services:</strong>{' '}
                                {formData.requested_services
                                    .map((id) => services.find((service) => service.id === id)?.title)
                                    .join(', ')}
                            </Typography>
                            <Typography variant="body1">
                                <strong>Details:</strong> {formData.details}
                            </Typography>
                        </Paper>
                        <Typography variant="body2" color="textSecondary">
                            Thank you for providing the details. We’ll get back to you with your personalized quote shortly!
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained" onClick={handleReset}>
                                Reset
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box>
                        {renderStepContent(activeStep)}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                            <Button disabled={activeStep === 0} onClick={handleBack}>
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                disabled={
                                    isLoading ||
                                    (activeStep === 0 && (!formData.name || !formData.email)) ||
                                    (activeStep === 1 && formData.requested_services.length === 0)
                                }
                                style={{
                                    color: '#fff',
                                }}
                            >
                                {!isLoading && activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                {isLoading && <CircularProgress size={24} />}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Paper>
            {/* Persistent Banner */}
            {/* <Paper
                elevation={3}
                sx={{
                    mt: 'auto', // Ensures it sticks to the bottom of the container
                    backgroundColor: 'primary.main',
                    color: '#fff',
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h6">
                    Estimated Price: ${averagePrice.toFixed(2)}
                </Typography>
            </Paper> */}
        </Container>
    );
};

export default QuoteFlow;
