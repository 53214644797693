import React from 'react';
import { Box, Grid, Typography, Link, TextField, Button } from '@mui/material';

const Footer = () => {
  return (
    <Box component="footer" sx={{ backgroundColor: '#1e1e1e', color: '#fff', padding: 4 }}>
      <Grid container spacing={4}>
        {/* Branding Section */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" gutterBottom>
            Codeductor
          </Typography>
          <Typography variant="body2">
            Orchestrating solutions at your tempo.
          </Typography>
        </Grid>

        {/* Navigation Links */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <Typography variant="body2">
            <Link href="/" color="inherit" underline="hover">
              Home
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link href="/services" color="inherit" underline="hover">
              Services
            </Link>
          </Typography>
          {/* <Typography variant="body2">
            <Link href="/blog" color="inherit" underline="hover">
              Blog
            </Link>
          </Typography> */}
          <Typography variant="body2">
            <Link href="/about" color="inherit" underline="hover">
              About Us
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link href="/about/contact" color="inherit" underline="hover">
              Contact
            </Link>
          </Typography>
          {/* <Typography variant="body2">
            <Link href="/login" color="inherit" underline="hover">
              Portal Login
            </Link>
          </Typography> */}
        </Grid>

        {/* Legal Links */}
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" gutterBottom>
            Legal
          </Typography>
          <Typography variant="body2">
            <Link href="/about/privacy-policy" color="inherit" underline="hover">
              Privacy Policy
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link href="/about/terms-of-service" color="inherit" underline="hover">
              Terms And Conditions
            </Link>
          </Typography>
          {/* <Typography variant="body2">
            <Link href="/about/sitemap" color="inherit" underline="hover">
              Sitemap
            </Link>
          </Typography> */}
        </Grid>

        {/* Newsletter Signup (Disabled For Now) */}
        {false && <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6" gutterBottom>
            Stay Connected
          </Typography>
          <Typography variant="body2" gutterBottom>
            Subscribe to our newsletter for the latest updates.
          </Typography>
          <Box component="form" sx={{ display: 'flex', gap: 1 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Your email"
              sx={{
                backgroundColor: '#fff',
                borderRadius: 1,
              }}
            />
            <Button variant="contained" sx={{ color: '#fff', backgroundColor: '#696969' }}>
              Subscribe
            </Button>
          </Box>
        </Grid>}
      </Grid>
      <Typography variant="body2" align="center" sx={{ marginTop: 4 }}>
        © {new Date().getFullYear()} Codeductor. All rights reserved.
      </Typography>
    </Box >
  );
};

export default Footer;
