import React, { useState } from 'react';
import { Container, Box, Typography, TextField, Button, Grid, Link, Paper, CircularProgress } from '@mui/material';

const ContactPage = () => {
    const [formData, setFormData] = useState({ name: '', email: '', details: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            // Replace this URL with your API endpoint
            const response = await fetch('/api/v1/contact', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                alert('Your message has been sent!');
                setFormData({ name: '', email: '', details: '' });
            } else {
                alert('Something went wrong. Please try again.');
            }
        } catch (error) {
            console.error(error);
            alert('There was an error submitting your message.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Paper
                sx={{
                    padding: 4,
                    textAlign: 'center',
                }}
            >
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Contact Us
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        We'd love to hear from you! Please fill out the form below or reach out to us directly via email.
                    </Typography>
                </Box>

                <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Your Name"
                                name="name"
                                fullWidth
                                variant="outlined"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Your Email"
                                name="email"
                                fullWidth
                                variant="outlined"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Your Message"
                                name="details"
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={4}
                                value={formData.details}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type='submit'
                                variant="contained"
                                fullWidth
                                disabled={isSubmitting}
                                style={{
                                    color: '#fff',
                                }}
                            >
                                {!isSubmitting ? 'Submit' : 'Submitting...'}
                                {isSubmitting && <CircularProgress size={24} />}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Typography variant="body1">
                        Prefer email?{' '}
                        <Link href="mailto:codeductor@gmail.com" color="primary">
                            codeductor@gmail.com
                        </Link>
                    </Typography>
                </Box>

                <Typography variant="body1" color="textSecondary">
                    If you're looking for services, please request a quote instead.
                </Typography>
                <Box sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        href="/about/contact/request"
                        size="large"
                        style={{
                            color: 'white',
                        }}
                    >
                        Request a Quote
                    </Button>
                </Box>
            </Paper>
        </Container >
    );
};

export default ContactPage;
