import { Paper, Container, Button, Link } from '@mui/material';
import Markdown from 'react-markdown';

import AvailableCodeductors from '../../../../components/AvailableCodeductors/AvailableCodeductors.jsx';


const markdown = `
# Team Building & Talent Acquisition

Build high-performing teams with our recruitment and organizational expertise. We craft job descriptions, design hiring strategies, and conduct technical and cultural interviews to ensure the best fit for your company.

## What We Offer
- **Recruitment Strategy:** Customized hiring strategies to attract top talent.
- **Job Descriptions:** Craft compelling job descriptions that attract the right candidates.
- **Interviews:** Conduct technical and cultural interviews to assess fit.
- **Onboarding:** Develop onboarding strategies to help new hires succeed.

## Why Choose Us
We’ve interviewed hundreds of candidates and built thriving teams that match the unique culture and needs of each business. Let’s help you find the right people who not only fit but elevate your team.
We work with your existing recruitment team or tools to help develop a strategy that works for your business.

## How it Works
1. **Consultation:** We start with a consultation to understand your unique needs and challenges.
2. **Strategy:** We craft a customized recruitment strategy to attract top talent.
3. **Interviews:** We conduct technical and cultural interviews to assess fit.
4. **Selection:** We help you select the best candidates for your team.

## Pricing
Pricing is based on the scope, complexity and the Codeductor selected for your project. Contact us for a custom quote or checkout our online quote tool for an estimate.
`

function TeamBuilding() {

    return (
        <Container
            sx={{
                margin: '0 auto',
                padding: '18px',
                width: 'calc(100vw - 44px)',
            }}
        >
            <Paper
                sx={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    padding: 4,
                    width: '100%',
                }}
            >
                <Markdown>
                    {markdown}
                </Markdown>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    href="/about/contact/request"
                    sx={{
                        color: 'white',
                        width: '256px',
                        height: '48px',
                        margin: '10px 0 20px 0',
                    }}
                >
                    Get a Quote
                </Button>
                <AvailableCodeductors category={'team-building'} />
            </Paper>
        </Container>
    )

}

export default TeamBuilding;