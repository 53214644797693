import { Paper, Container, Typography, Button, Link } from '@mui/material';
import Markdown from 'react-markdown';

import AvailableCodeductors from '../../../../components/AvailableCodeductors/AvailableCodeductors.jsx';



const markdown = `
# Contract Development/Individual Work

Hands-on execution of technical tasks to bring your projects to life. Whether you need expert-level individual contributors or a collaborative team effort, we provide the flexibility and expertise to handle your development needs. From coding and debugging to implementing features and optimizing systems, our contract work is designed to seamlessly integrate into your existing processes and deliver high-quality results. Ideal for clients who need expert contributors to execute tasks identified during our consulting phase.

## What We Offer
- **Frontend Development:** We build the user-facing side of your application, focusing on the look, feel, and user experience.
- **Backend Development:** We build the server-side of your application, focusing on the logic, database, server, and application.
- **Full Stack Development:** We build both the frontend and backend of your application, providing a complete solution.
- **Mobile Development:** We build mobile applications for iOS and Android devices.

## Why Choose Us
Let us use our extensive experience in app development, combined with a proven ability to deliver high-quality solutions, ensures that your technical needs are in expert hands. Whether it’s feature implementation, debugging, or custom development, we provide reliable, professional service backed by a team with decades of combined expertise in delivering impactful results.

## How it Works
1. **Consultation:** We start with a consultation to understand your unique needs and challenges.
2. **Development:** Our team executes on the tasks identified during the consultation phase.
3. **Testing:** We rigorously test our work to ensure it meets your requirements.
4. **Delivery:** We deliver the final product to you for review and implementation.

## Pricing
Pricing is based on the scope, complexity and the Codeductor selected for your project. Contact us for a custom quote or checkout our online quote tool for an estimate.

`

function AppDevelopment() {

    return (
        <Container
            style={{
                margin: '0 auto',
                padding: '18px',
                width: 'calc(100vw - 44px)',
            }}
        >
            <Paper
                sx={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    padding: 4,
                    width: '100%',
                }}
            >
                <Markdown>
                    {markdown}
                </Markdown>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    href="/about/contact/request"
                    sx={{
                        color: 'white',
                        width: '256px',
                        height: '48px',
                        margin: '10px 0 20px 0',
                    }}
                >
                    Get a Quote
                </Button>
                <AvailableCodeductors category={'contractor'} />
            </Paper>
        </Container>
    )

}

export default AppDevelopment;