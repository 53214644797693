import { Paper, Container, Button, Link } from '@mui/material';
import Markdown from 'react-markdown';

import AvailableCodeductors from '../../../../components/AvailableCodeductors/AvailableCodeductors.jsx';

const markdown = `
# Strategic Solutions Architecture & Process Mapping

Streamline your operations with comprehensive solutions architecture services. We create actionable roadmaps and process maps to align your business goals with technology strategies for maximum efficiency and scalability. We can meet directly with your clients to provide the highest value, for an additional cost.

## What We Offer
- **Solutions Architecture:** Comprehensive solutions architecture services to streamline your operations.
- **Process Mapping:** Actionable roadmaps and process maps to align your business goals with technology strategies.
- **Client Meetings:** Direct client meetings to provide the highest value, for an additional cost.

## Why Choose Us
We bring years of experience crafting roadmaps and process improvements, we excel in aligning business goals with technology strategies. Our track record of building teams and defining company culture ensures we approach every project with professionalism and an eye for long-term success. Whether you're scaling operations or optimizing workflows, we bring the experience and foresight to deliver impactful solutions.

## How it Works
1. **Consultation:** We start with a consultation to understand your unique needs and challenges.
2. **Roadmap:** We craft a comprehensive solutions architecture roadmap to streamline your operations.
3. **Process Mapping:** We create actionable roadmaps and process maps to align your business goals with technology strategies.
4. **Client Meetings:** We can meet directly with your clients to provide the highest value, for an additional cost.

## Pricing
Pricing is based on the scope, complexity, and the Codeductor selected for your project. Contact us for a custom quote or check out our online quote tool for an estimate.

`

function SolutionsArchitect() {

    return (
        <Container
            sx={{
                margin: '0 auto',
                padding: '18px',
                width: 'calc(100vw - 44px)',
            }}
        >
            <Paper
                sx={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    padding: 4,
                    width: '100%',
                }}
            >
                <Markdown>
                    {markdown}
                </Markdown>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    href="/about/contact/request"
                    sx={{
                        color: 'white',
                        width: '256px',
                        height: '48px',
                        margin: '10px 0 20px 0',
                    }}
                >
                    Get a Quote
                </Button>
                <AvailableCodeductors category={'solution-architect'} />
            </Paper>
        </Container>
    )

}

export default SolutionsArchitect;