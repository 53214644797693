import { Paper, Container, Button, Link } from '@mui/material';
import Markdown from 'react-markdown';

import AvailableCodeductors from '../../../../components/AvailableCodeductors/AvailableCodeductors.jsx';


const markdown = `
# Developer Training & Team Enhancement Programs

Empower your development team with expert training and personalized coaching. We provide code reviews, debugging guidance, and skill-building sessions tailored to your team’s needs, ensuring a more efficient and capable workforce.

## What We Offer
- **Code Reviews:** In-depth code reviews to identify areas for improvement.
- **Debugging Guidance:** Expert guidance to help your team solve complex problems.
- **Skill Building:** Customized training sessions to enhance your team’s skills.
- **Coaching:** Personalized coaching to help your team grow and succeed.

## Why Choose Us
We’ve mentored hundreds of developers, interviewed extensively, and built teams that thrive on collaboration and technical excellence. With firsthand experience in defining culture and ensuring quality at every stage, we offer more than just training—we provide the tools and guidance to create empowered, high-performing development teams.

## How it Works
1. **Consultation:** We start with a consultation to understand your team’s unique needs and challenges.
2. **Training Plan:** We craft a customized training plan to enhance your team’s skills.
3. **Coaching:** We provide personalized coaching to help your team grow and succeed.
4. **Follow-up:** We follow up with your team to ensure the training is effective and impactful.

## Pricing
Pricing is based on the scope, complexity, and the Codeductor selected for your project. Contact us for a custom quote or check out our online quote tool for an estimate.

`

function DeveloperTraining() {

    return (
        <Container
            sx={{
                margin: '0 auto',
                padding: '18px',
                width: 'calc(100vw - 44px)',
            }}
        >
            <Paper
                sx={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    padding: 4,
                    width: '100%',
                }}
            >
                <Markdown>
                    {markdown}
                </Markdown>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    href="/about/contact/request"
                    sx={{
                        color: 'white',
                        width: '256px',
                        height: '48px',
                        margin: '10px 0 20px 0',
                    }}
                >
                    Get a Quote
                </Button>
                <AvailableCodeductors category={'developer-training'} />
            </Paper>
        </Container>
    )

}

export default DeveloperTraining;