import React, { createContext, useContext, useEffect, useState } from 'react';
import Axios from 'axios';

import useIsEmbedded from '../../hooks/useIsEmbedded'

// Create the Auth context
const AuthContext = createContext();

// Custom hook to use the Auth context
export const useAuth = (options = {}) => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {

  const isEmbedded = useIsEmbedded();

  const tokenDetailsStorageKey = '_t';

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(sessionStorage.getItem(tokenDetailsStorageKey));


  const checkToken = async (token) => {
    try {
      const response = await Axios.get('/api/v1/users/auth/validate-token', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.status === 202; 
    } catch (error) {
      console.error('Token validation failed', error);
      return false;
    }
  }

  const login = async (token) => {
    const isValid = await checkToken(token);
    if (isValid) {
      setIsAuthenticated(true);
      setToken(token);
      sessionStorage.setItem(tokenDetailsStorageKey, token);
    } else {
      setIsAuthenticated(false);
      setToken(null);
      sessionStorage.removeItem(tokenDetailsStorageKey);
    }
  };

  const logout = () => {
    // firebase.auth().signOut();
    sessionStorage.removeItem(tokenDetailsStorageKey);
    setToken(null);
    setIsAuthenticated(false);
  };


  useEffect(() => {

    async function processToken(token) {
      if (token) {
        const isValid = await checkToken(token);
        if (isValid) {
          setIsAuthenticated(true);
          setToken(token);
          sessionStorage.setItem(tokenDetailsStorageKey, token);
        } else {
          setIsAuthenticated(false);
          sessionStorage.removeItem(tokenDetailsStorageKey);
        }
      } else {
        setIsAuthenticated(false);
        setToken(null);
        sessionStorage.removeItem(tokenDetailsStorageKey);
      }
    }

    const currentToken = token;

    if (currentToken) {
      processToken(currentToken);
    }

  }, [token, !isEmbedded]);



  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, token }}>
      {children}
    </AuthContext.Provider>
  );
};
