export default [
    {
        id: "seo",
        callToActionText: "Start Ranking Today",
        title: "Comprehensive SEO Services",
        flavorText: "Boost your visibility and drive traffic.",
        description: "We specialize in tailored SEO strategies, from technical audits to keyword optimization, helping your business rank higher and grow online.",
        link: "/services/seo",
        order: 6,
    },
    {
        id: "training",
        callToActionText: "Boost Your Team's Skills",
        title: "Developer Training & Team Enhancement Programs",
        flavorText: "Empower your team with expertise.",
        description: "Upskill your development team with personalized training, code reviews, and coaching sessions designed to improve efficiency and collaboration.",
        link: "/services/training",
        order: 3,
    },
    {
        id: "consulting",
        callToActionText: "Get Started Today",
        title: "Custom Technical Consulting & System Design",
        flavorText: "Solve complex challenges with ease.",
        description: "Our experts provide actionable insights and strategic guidance to tackle your toughest technical problems and optimize your systems.",
        link: "/services/consulting",
        order: 1,
    },
    {
        id: "team-building",
        callToActionText: "Build Your Dream Team",
        title: "Team Building & Talent Acquisition",
        flavorText: "Build a team that thrives.",
        description: "From crafting job descriptions to conducting interviews, we help you find and hire the perfect candidates for your organization.",
        link: "/services/team-building",
        order: 2,
    },
    {
        id: "process-mapping",
        callToActionText: "Optimize Your Workflows",
        title: "Strategic Solutions Architecture & Process Mapping",
        flavorText: "Streamline your operations.",
        description: "Optimize workflows and align your business processes with clear, actionable roadmaps tailored to your goals.",
        link: "/services/process-mapping",
        order: 4,
    },
    {
        id: "contract-development",
        callToActionText: "Let's Build Together",
        title: "Contract Development/Individual Work",
        flavorText: "Hands-on execution when you need it.",
        description: "From coding to debugging, our expert developers seamlessly integrate into your team to deliver high-quality results.",
        link: "/services/contract-development",
        order: 5,
    }
].sort((a, b) => a.order - b.order);