import { Paper, Container, Typography, Button, Link } from '@mui/material';
import Markdown from 'react-markdown';

import AvailableCodeductors from '../../../../components/AvailableCodeductors/AvailableCodeductors.jsx';


const markdown = `
# Custom Technical Consulting & System Design

Solve complex technical challenges with expert consulting on system architecture, best practices, and tailored solutions for your business. 
Our services include detailed documentation and actionable recommendations to drive success. Ideal for short-term projects or specific challenges.

## What We Offer
- **Technical Consulting:** Get expert advice on system architecture, best practices, and more.
- **System Design:** Tailored solutions to meet your unique business needs.
- **Documentation:** Detailed reports and recommendations to drive success.

## Why Choose Us
We work alongside you to tackle your toughest technical challenges with a mix of strategic insights and hands-on expertise. Our focus is always on delivering practical solutions that make a real difference for your business.

## How it Works
1. **Consultation:** We start with a consultation to understand your unique needs and challenges.
2. **Analysis:** Our team conducts a thorough analysis of your systems and processes.
3. **Recommendations:** We provide detailed recommendations and actionable insights.
4. **Implementation:** We work with you to implement the solutions that will drive success.

## Pricing
Pricing is based on the scope, complexity and the Codeductor selected for your project. Contact us for a custom quote or checkout our online quote tool for an estimate.
`

function Consulting() {

    return (
        <Container
            style={{
                margin: '0 auto',
                padding: '18px',
                width: 'calc(100vw - 44px)',
            }}
        >
            <Paper
                sx={{
                    overflow: 'hidden',
                    textAlign: 'left',
                    padding: 4,
                    width: '100%',
                }}
            >
                <Markdown>
                    {markdown}
                </Markdown>
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    href="/about/contact/request"
                    sx={{
                        color: 'white',
                        width: '256px',
                        height: '48px',
                        margin: '10px 0 20px 0',
                    }}
                >
                    Get a Quote
                </Button>
                <AvailableCodeductors category={'consulting'} />
            </Paper>
        </Container>
    )

}

export default Consulting;